import { CONFIG } from './config'

export const WORDS = [
  'qamum',
  'ʕinmi',
  'hupał',
  'w̓atyu',
  'tuxʷuł',
  'k̓ucim',
  'ʕimin',
  'ƛ̓upač',
  'wax̣ni',
  'ʕamiq',
  'ʕanmi',
  'c̓imt̓u',
  'mutuḥ',
  't̓icka',
  'łaqna',
  'ƛ̓uč̓im',
  'nuč̓ak',
  'ʕanaq',
  'nism̓a',
  'ḥičin',
  'kʷikma',
  't̓iič̓ak',
  'k̓aḥča',
  'pic̓up',
  'kač̓as',
  'suč̓as',
  'qamim',
  'łaqʷac',
  'y̓aʔis',
  'k̓anin',
  'ʕičnu',
  'ƛ̓uč̓um',
  'č̓ukna',
  'č̓ukʷna',
  'pic̓ip',
  'č̓ałča',
  'tup̓ał',
  't̓ac̓ap',
  'kʷan̓is',
  'suč̓is',
  'ƛ̓akʷin',
  'łim̓aq',
  'situp',
  'k̓uc̓im',
  'p̓uʔup',
  'c̓aʔak',
  'nusči',
  'č̓uč̓ak',
  'č̓isnu',
  'watyu',
  'pakʷin',
  'k̓aḥči',
  'muqc̓u',
  'c̓uč̓ak',
  'qač̓ak',
  'c̓iwaap',
  'hum̓aq',
  'tukukʷ',
  'ƛ̓iḥma',
  'nuč̓aq',
  't̓uc̓up',
  'ʕamin',
  'ƛusit',
  'ʕaʔuk',
  'k̓uc̓am',
  'ʕuqʷac',
  'ʕam̓it',
  'muwač',
  'k̓eʔin',
  'm̓ač̓ak',
  'qač̓ik',
  'k̓uc̓um',
  'tuqʷac',
  'n̓učak',
  'ʕaʕči',
  'patʕu',
  'šuw̓ił',
  'ʔusit',
  'ʕat̓aw',
  'č̓ikna',
  'nixc̓u',
  'qum̓ic',
  'p̓aʕim',
  'n̓uč̓ak',
  'cuw̓it',
  'qičin',
  'ʕinm̓a',
  'niḥuk',
  'kist̓a',
  'mux̣c̓u',
  'ʕaʔukʷ',
  'niḥc̓a',
  'qiw̓aḥ',
  'saunt',
  'c̓iy̓aš',
  'ʕinm̓i',
  'c̓iyup',
  'haʔum',
  't̓ak̓ʷas',
  'ƛ̓aanat',
  'qaqup',
  'niḥc̓u',
  't̓at̓us',
  'p̓aʕum',
  'kʷan̓us',
  'kanac',
  'šuʔuł',
  'm̓ay̓ak',
]

if (CONFIG.normalization) {
  WORDS.forEach((val, i) => (WORDS[i] = val.normalize(CONFIG.normalization)))
}

function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
  }
}

if (CONFIG.shuffle) {
  shuffle(WORDS)
}
